import React, { Component } from 'react';
import { View, TouchableOpacity, SafeAreaView, Image, ActivityIndicator } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class NewBusiness extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            loading: false
        };
    }

    async previous() {
        this.state.navigation.navigate('Landing');
    }

    async next() {
        this.setState({
            loading: true
        })
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/form',
            data: {
                code: this.state.details.business.code,
                business: this.state.details.business.id
            }
        }).then((response) => {
            if (response.data.success === true) {
                let newObject = {
                    code: this.state.details.code,
                    business: this.state.details.business,
                    form: response.data.form
                };
                this.state.navigation.navigate('Form', { details: newObject });
            }
        }).catch((error) => {
            this.setState({
                loading: false
            })
            console.log(error);
        })
    }

    render() {

        let layout = (
            <View style={{ width: "100%" }}>
                <View style={{ width: '100%' }}>
                    <Heading text="Is this your scanning centre?" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoRegular" inline={true} />
                </View>
                <View style={{ width: '100%', alignItems: 'center' }}>
                    <Heading text={this.state.details.business.name} color="#a70f14" textAlign="center" fontSize={RF(4.0)} fontFamily="NunitoExtraBold" inline={true} />
                </View>
                <View style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', marginTop: 10 }}>
                    <View style={{ width: "40%", paddingRight: 10 }}>
                        <TouchableOpacity onPress={() => this.previous()}>
                            <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 2, borderColor: "#a70f14", justifyContent: 'center', alignItems: 'center' }}>
                                <Ionicons name="ios-thumbs-down" size={24} color="#a70f14" />
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={{ width: "40%", paddingLeft: 10 }}>
                        <TouchableOpacity onPress={() => this.next()}>
                            <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 2, borderColor: "#149f84", justifyContent: 'center', alignItems: 'center' }}>
                                <Ionicons name="ios-thumbs-up" size={24} color="#149f84" />
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )

        if (this.state.loading) {
            layout = <ActivityIndicator />
        }

        return (
            <View style={{ flex: 1, backgroundColor: "#FFF" }}>
                <SafeAreaView style={{ flex: 1, flexDirection: "column" }}>
                    <View style={{ width: "100%", marginTop: 20 }}>
                        <View style={{ width: "100%" }}>
                            <Image source={require('../../assets/img/icon.jpg')} resizeMode="contain" style={{ width: "100%", height: 120 }} />
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, justifyContent: 'center', alignItems: "center", flexDirection: "column", paddingLeft: 20, paddingRight: 20 }}>
                        {layout}
                    </View>
                    <View style={{ width: "100%", marginBottom: 15, marginTop: 15, paddingLeft: 20, paddingRight: 20 }}>
                        <Heading text="By completing this form process you agree to BlipperSCREEN's Terms of Use and acknowledge you have read the Privacy Policy." color="#999" textAlign="left" fontSize={RF(2.2)} fontFamily="NunitoRegular" inline={true} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
