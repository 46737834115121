import React, { Component } from 'react';
import { View, SafeAreaView, TouchableOpacity, KeyboardAvoidingView, Platform, TextInput } from 'react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class Temprature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            temprature: ''
        };
    }

    async submit() {
        let dataObject = this.state.details;
        dataObject.form = JSON.stringify(dataObject.form);
        dataObject.questions = JSON.stringify(dataObject.questions);
        dataObject.temprature = this.state.temprature;
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/submit',
            data: dataObject
        }).then((response) => {
            if(response.data.success === true) {
                let newObject = {
                    code: this.state.details.code,
                    business: this.state.details.business,
                    form: this.state.details.form,
                    questions: this.state.details.questions,
                    temprature: this.state.temprature
                };
                this.state.navigation.navigate('Complete', { details: newObject });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    async storeUser(data) {
      try {
        await AsyncStorage.setItem('users', JSON.stringify(data));
      } catch(error) {
        console.log(error);
      }
    }

    render() {

        return (
            <View style={{ flex: 1 }}>
                <SafeAreaView style={{ flex: 1, justifyContent: 'center' }}>
                    <View style={{ flex: 1, backgroundColor: '#9B66FE', margin: 20, paddingLeft: 20, paddingRight: 20, position: "relative", borderRadius: 20, flexDirection: 'column' }}>
                        <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }} behavior={Platform.OS == "ios" ? "padding" : "height"}>
                            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center' }}>
                                <Heading text="Your temprature" color="#FFFF" textAlign="center" fontSize={RF(4.0)} fontFamily="NunitoExtraBold" inline={true} />
                            </View>
                            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center', marginTop: 10 }}>
                                <View style={{ width: "60%", height: 80, position: 'relative'  }}>
                                    <TextInput keyboardType="default" secureTextEntry={false} placeholder="C°" style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "#FFF", padding: 10, fontFamily: 'NunitoExtraBold', borderRadius: 10, textAlign: 'center', fontSize: RF(4.0) }} returnKeyType="done" onSubmitEditing={this.next} onChangeText={(text)=>{ this.setState({ temprature: text }) }} />
                                </View>
                            </View>
                        </KeyboardAvoidingView>
                        <View style={{ width: '100%', paddingLeft: 30, paddingRight: 30, alignItems: 'center', marginTop: 10, flexDirection: 'row', paddingBottom: 30, left: 0, justifyContent: 'center' }}>
                            <View style={{ width: "50%", paddingLeft: 10 }}>
                                <TouchableOpacity onPress={()=> this.submit() }>
                                    <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                                        <Heading text="Submit" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
