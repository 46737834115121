import React, { Component } from 'react';
import { View, TouchableOpacity, SafeAreaView, Image, ActivityIndicator } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class NewBusinessSick extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        let layout = (
            <View style={{ width: "100%" }}>
                <View style={{ width: '100%', alignItems: 'center' }}>
                    <Heading text="Please see your doctor." color="#a70f14" textAlign="center" fontSize={RF(4.0)} fontFamily="NunitoExtraBold" inline={true} />
                </View>
            </View>
        )

        if (this.state.loading) {
            layout = <ActivityIndicator />
        }

        return (
            <View style={{ flex: 1, backgroundColor: "#FFF" }}>
                <SafeAreaView style={{ flex: 1, flexDirection: "column" }}>
                    <View style={{ width: "100%", marginTop: 20 }}>
                        <View style={{ width: "100%" }}>
                            <Image source={require('../../assets/img/icon.jpg')} resizeMode="contain" style={{ width: "100%", height: 120 }} />
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, justifyContent: 'center', alignItems: "center", flexDirection: "column", paddingLeft: 20, paddingRight: 20 }}>
                        {layout}
                    </View>
                    <View style={{ width: "100%", padding: 20 }}>
                        <TouchableOpacity onPress={() => this.props.navigation.navigate('Landing')}>
                            <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 2, borderColor: "#149f84", justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                                <Heading text="New" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoExtraBold" inline={true} marginRight={10} />
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={{ width: "100%", marginBottom: 15, paddingLeft: 20, paddingRight: 20 }}>
                        <Heading text="By completing this form process you agree to BlipperSCREEN's Terms of Use and acknowledge you have read the Privacy Policy." color="#999" textAlign="left" fontSize={RF(2.2)} fontFamily="NunitoRegular" inline={true} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
