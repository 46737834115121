import React, { Component } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView, Platform } from 'react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';
import { TextInput, TouchableOpacity } from 'react-native-gesture-handler';

export default class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            form: [],
            name: '',
            id: ''
        };
    }

    componentDidMount() {
        this.setState({
            form: this.props.route.params.details.form
        })
    }

    async next() {
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/questions',
            data: {
                code: this.state.details.code,
                business: this.state.details.business.id
            }
        }).then((response) => {
            console.log(response.data);
            if(response.data.success === true) {
                let newObject = {
                    code: this.state.details.code,
                    business: this.state.details.business,
                    form: this.state.details.form,
                    questions: response.data.questions
                };
                this.state.navigation.navigate('Questions', { details: newObject });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    async update(index, value) {
        let currentState = await this.state.details;
        let newForm = this.state.form;
        newForm[index].value = value;
        currentState.form = newForm;
        this.setState({
            details: currentState
        })
        console.log(this.state);
    }

    render() {

        let questions = <View />;
        
        if(this.state.form) {
            let json = this.state.form;
            questions = json.map((item, index)=>{
                return (
                    <View key={index} style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center', marginTop: 10 }}>
                        <View style={{ width: "100%", height: 50, position: 'relative'  }}>
                            <TextInput keyboardType="default" secureTextEntry={false} placeholder={item.placeholder} style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "#FFF", padding: 10, fontFamily: 'NunitoExtraBold', borderRadius: 10, textAlign: 'center', fontSize: RF(2.5) }} onChangeText={(text)=>{this.update(index, text)}} />
                        </View>
                    </View>
                )
            })
        }

        return (
            <View style={{ flex: 1 }}>
                <SafeAreaView style={{ flex: 1, justifyContent: 'center' }}>
                    <View style={{ flex: 1, backgroundColor: '#9B66FE', margin: 20, paddingLeft: 20, paddingRight: 20, position: "relative", borderRadius: 20, flexDirection: 'column' }}>
                        <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }} behavior={Platform.OS == "ios" ? "padding" : "height"}>
                            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center' }}>
                                <Heading text="Please enter the details below" color="#FFFF" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoExtraBold" inline={true} />
                            </View>
                            {questions}
                        </KeyboardAvoidingView>
                        <View style={{ width: '100%', paddingLeft: 30, paddingRight: 30, alignItems: 'center', marginTop: 10, flexDirection: 'row', paddingBottom: 30, left: 0, justifyContent: 'center' }}>
                            <View style={{ width: "50%", paddingLeft: 10 }}>
                                <TouchableOpacity onPress={()=> this.next() }>
                                    <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                                        <Heading text="Next" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
