import React, { Component } from 'react';
import { View, TouchableOpacity, SafeAreaView, ActivityIndicator } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';
import { ScrollView } from 'react-native-gesture-handler';

export default class Questions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            questions: [],
            next: false
        }
    }

    componentDidMount() {
        this.setState({
            questions: this.state.details.questions
        },()=>{
            this.checkAnswers()
        })
    }

    async selectAllNo() {
        let newQuestions = [];
        let questions = this.state.questions;
        questions.map((item)=>{
            item.answer = false;
            newQuestions.push(item);
        })
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async selectAllYes() {
        let newQuestions = [];
        let questions = this.state.questions;
        questions.map((item)=>{
            item.answer = true;
            newQuestions.push(item);
        })
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async yes(index) {
        let newQuestions = this.state.questions;
        newQuestions[index].answer = true;
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async no(index) {
        let newQuestions = this.state.questions;
        newQuestions[index].answer = false;
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async checkAnswers() {
        let completed = true;
        let allQuestions = this.state.questions;
        allQuestions.map((question)=>{
            if(question.answer === false || question.answer === true){

            }else{
                completed = false;
            }
        })
        if(completed) {
            this.setState({
                next: true
            })
        }
    }

    async next() {

        this.state.navigation.navigate('Temprature', { details: this.state.details });

    }

    render() {

        let questions = <View />
        let layout = <View />
        let button = <View />

        if(this.state.questions) {
            let json = this.state.questions;
            questions = json.map((item, index)=>{
                let buttonNo = (
                    <TouchableOpacity onPress={()=> this.no(index) }>
                        <View style={{ flexDirection: "row", width: 50, height: 50, borderRadius: 25, borderWidth: 1, borderColor: '#CCC', justifyContent: 'center', alignItems: 'center', marginRight: 5 }}>
                            <Ionicons name="ios-thumbs-down" size={24} color="#CCC" />
                        </View>
                    </TouchableOpacity>
                );
                let buttonYes = (
                    <TouchableOpacity onPress={()=> this.yes(index) }>
                        <View style={{ flexDirection: "row", width: 50, height: 50, borderRadius: 25, borderWidth: 1, borderColor: '#CCC', justifyContent: 'center', alignItems: 'center' }}>
                            <Ionicons name="ios-thumbs-up" size={24} color="#CCC" />
                        </View>
                    </TouchableOpacity>
                );
    
                if(item.answer === true) {
                    buttonYes = (
                        <TouchableOpacity onPress={()=> this.yes(index) }>
                            <View style={{ flexDirection: "row", width: 50, height: 50, borderRadius: 25, borderWidth: 1, borderColor: '#a70f14', justifyContent: 'center', alignItems: 'center' }}>
                                <Ionicons name="ios-thumbs-up" size={24} color="#a70f14" />
                            </View>
                        </TouchableOpacity>
                    );
                }
                
                if(item.answer === false) {
                    buttonNo = (
                        <TouchableOpacity onPress={()=> this.no(index) }>
                            <View style={{ flexDirection: "row", width: 50, height: 50, borderRadius: 25, borderWidth: 1, borderColor: '#a70f14', justifyContent: 'center', alignItems: 'center', marginRight: 5 }}>
                                <Ionicons name="ios-thumbs-down" size={24} color="#a70f14" />
                            </View>
                        </TouchableOpacity>
                    );
                }
    
                return (
                    <View key={index} style={{ width: "100%", backgroundColor: '#FFF', marginBottom: 40 }}>
                        <View style={{ width: '100%', flexDirection: "row" }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start', paddingRight: 10 }}>
                                <Heading text={item.name} color="#000" textAlign="left" fontSize={RF(2.0)} fontFamily="NunitoExtraBold" inline={true} />
                            </View>
                            <View style={{ justifyContent: 'flex-end', alignItems: 'flex-start', flexDirection: "row" }}>
                                {buttonNo}
                                {buttonYes}
                            </View>
                        </View>
                    </View>
                )
            })
        }

        if (this.state.loading) {
            layout = <ActivityIndicator />
        }else{
            layout = (
                <ScrollView style={{ flex: 1, marginTop: 10 }} keyboardDismissMode="on-drag">
                    {questions}
                </ScrollView>
            )
        }

        if(this.state.next) {
            button = (
                <View style={{ width: "100%", paddingTop: 20, paddingHorizontal: 20, backgroundColor: "#F0F0F0" }}>
                    <TouchableOpacity onPress={() => this.next()}>
                        <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 2, borderColor: "#149f84", justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <Heading text="Next" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoExtraBold" inline={true} marginRight={10} />
                        </View>
                    </TouchableOpacity>
                </View>
            )
        }

        return (
            <View style={{ flex: 1, backgroundColor: "#FFF" }}>
                <SafeAreaView style={{ flex: 1, flexDirection: "column" }}>
                    <View style={{ width: "100%", marginTop: 30, marginBottom: 10 }}>
                        <View style={{ width: '100%' }}>
                            <Heading text="Please enter the details below" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoRegular" inline={true} />
                        </View>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: 'flex-end', marginRight: 20, marginTop: 10, marginBottom: 10 }}>
                        <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginRight: 5 }}>
                            <TouchableOpacity onPress={()=> this.selectAllNo() }>
                                <View style={{ flexDirection: "row", width: 50, height: 50, borderRadius: 25, borderWidth: 1, borderColor: '#CCC', justifyContent: 'center', alignItems: 'center' }}>
                                    <Ionicons name="ios-thumbs-down" size={24} color="#CCC" />
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            <TouchableOpacity onPress={()=> this.selectAllYes() }>
                                <View style={{ flexDirection: "row", width: 50, height: 50, borderRadius: 25, borderWidth: 1, borderColor: '#CCC', justifyContent: 'center', alignItems: 'center' }}>
                                    <Ionicons name="ios-thumbs-up" size={24} color="#CCC" />
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, paddingLeft: 20, paddingRight: 20 }}>
                        {layout}
                    </View>
                    {button}
                    <View style={{ width: "100%", paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, backgroundColor: "#F0F0F0" }}>
                        <Heading text="By completing this form process you agree to BlipperSCREEN's Terms of Use and acknowledge you have read the Privacy Policy." color="#999" textAlign="left" fontSize={RF(2.2)} fontFamily="NunitoRegular" inline={true} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
