import React, { Component } from 'react';
import { View, SafeAreaView, TouchableOpacity } from 'react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details
        };
    }

    async previous() {
        this.state.navigation.navigate('Landing');
    }

    async next() {
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/form',
            data: {
                code: this.state.details.business.code,
                business: this.state.details.business.id
            }
        }).then((response) => {
            if(response.data.success === true) {
                let newObject = {
                    code: this.state.details.code,
                    business: this.state.details.business,
                    form: response.data.form
                };
                this.state.navigation.navigate('Form', { details: newObject });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        return (
            <View style={{ flex: 1 }}>
                <SafeAreaView style={{ flex: 1, justifyContent: 'center' }}>
                    <View style={{ flex: 1, backgroundColor: '#9B66FE', margin: 20, paddingLeft: 20, paddingRight: 20, position: "relative", borderRadius: 20, flexDirection: 'column' }}>
                        <View style={{ flexGrow: 1, justifyContent: 'center' }}>
                            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center' }}>
                                <Heading text="Is your scanning centre?" color="#FFFF" textAlign="center" fontSize={RF(2.5)} fontFamily="NunitoExtraBold" inline={true} />
                            </View>
                            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center' }}>
                                <Heading text={this.state.details.business.name} color="#FFFF" textAlign="center" fontSize={RF(4.0)} fontFamily="NunitoExtraBold" inline={true} />
                            </View>
                        </View>
                        <View style={{ width: '100%', paddingLeft: 30, paddingRight: 30, alignItems: 'center', marginTop: 10, flexDirection: 'row', paddingBottom: 30, left: 0 }}>
                            <View style={{ width: "50%", paddingRight: 10 }}>
                                <TouchableOpacity onPress={()=>this.previous()}>
                                    <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                                        <Heading text="No" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                            <View style={{ width: "50%", paddingLeft: 10 }}>
                                <TouchableOpacity onPress={()=> this.next() }>
                                    <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                                        <Heading text="Yes" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
