import React, { Component } from 'react';
import { View, TextInput, SafeAreaView, Image, ActivityIndicator, KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class Temprature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            temprature: ''
        };
        this.checkFault = this.checkFault.bind(this);
    }

    async submit() {
        let dataObject = this.state.details;
        dataObject.form = JSON.stringify(dataObject.form);
        dataObject.questions = JSON.stringify(dataObject.questions);
        dataObject.temprature = this.state.temprature;
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/submit',
            data: dataObject
        }).then((response) => {
            if(response.data.success === true) {
                let newObject = {
                    code: this.state.details.code,
                    business: this.state.details.business,
                    form: this.state.details.form,
                    questions: this.state.details.questions,
                    temprature: this.state.temprature
                };
                console.log(newObject);
                if(this.checkFault() === 'temprature') {
                    this.state.navigation.navigate('Complete', { details: newObject, highTemprature: true, sick: false, pin: response.data.pin });
                }else if(this.checkFault() === 'sick') {
                    this.state.navigation.navigate('Complete', { details: newObject, highTemprature: false, sick: true, pin: response.data.pin });
                }else{
                    this.state.navigation.navigate('Complete', { details: newObject, highTemprature: false, sick: false, pin: response.data.pin });
                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    checkFault() {
        let sick = false;
        let questions = JSON.parse(this.state.details.questions);
        questions.map((question)=>{
            if(question.answer === true) {
                sick = true;
            }
        })
        if(this.state.temprature > 37.9) {
            return 'temprature';
        } else if(sick) {
            return 'sick';
        } else{
            return 'fine';
        }
    }

    changeComma(text) {
        let transformed = text.replace(',', '.');
        this.setState({ temprature: transformed })
    }

    render() {

        let button = <View />

        let layout = (
            <View style={{ width: "100%" }}>
                <View style={{ width: '100%' }}>
                    <Heading text="Your temprature" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoRegular" inline={true} />
                </View>
                <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center', marginTop: 10 }}>
                    <View style={{ width: "60%", height: 80, position: 'relative'  }}>
                        <TextInput keyboardType="decimal-pad" secureTextEntry={false} placeholder="C°" style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "#FAFAFA", padding: 10, fontFamily: 'NunitoExtraBold', borderRadius: 10, textAlign: 'center', fontSize: RF(4.0) }} returnKeyType="done" onSubmitEditing={this.next} onChangeText={(text)=>{ this.changeComma(text) }} />
                    </View>
                </View>
            </View>
        )

        if (this.state.loading) {
            layout = <ActivityIndicator />
        }

        if (this.state.temprature) {
            button = (
                <View style={{ width: "100%", paddingTop: 20, paddingHorizontal: 20, backgroundColor: "#F0F0F0" }}>
                    <TouchableOpacity onPress={() => this.submit()}>
                        <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 2, borderColor: "#149f84", justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <Heading text="Next" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoExtraBold" inline={true} marginRight={10} />
                        </View>
                    </TouchableOpacity>
                </View>
            )
        }

        return (
            <View style={{ flex: 1, backgroundColor: "#FFF" }}> 
                <SafeAreaView style={{ flex: 1, flexDirection: "column" }}>
                    <View style={{ width: "100%", marginTop: 20 }}>
                        <View style={{ width: "100%" }}>
                            <Image source={require('../../assets/img/icon.jpg')} resizeMode="contain" style={{ width: "100%", height: 120 }} />
                        </View>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        <View style={{ flexGrow: 1, justifyContent: 'center', alignItems: "center", flexDirection: "column", paddingLeft: 20, paddingRight: 20 }}>
                            {layout}
                        </View>
                    </View>
                    {button}
                    <View style={{ width: "100%", paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, backgroundColor: "#F0F0F0" }}>
                        <Heading text="By completing this form process you agree to BlipperSCREEN's Terms of Use and acknowledge you have read the Privacy Policy." color="#999" textAlign="left" fontSize={RF(2.2)} fontFamily="NunitoRegular" inline={true} />
                    </View>
                </SafeAreaView>
            </View>
        );
        
    }

}
