import React, { Component } from 'react';
import { View, Text } from 'react-native';

export default class Heading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectable: this.props.notSelectable ? false : true
    };
  }

  render() {
    let heading;
    if(this.props.inline){
      heading = (
        <Text allowFontScaling={false} style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize, color: this.props.color, lineHeight: this.props.lineHeight, marginBottom: this.props.marginBottom, marginTop: this.props.marginTop, marginRight: this.props.marginRight, textAlign: this.props.textAlign }}>{this.props.text}</Text>
      )
    }else{
      heading = (
        <View style={{ width: "100%" }}>
          <Text allowFontScaling={false} ellipsizeMode='tail' numberOfLines={this.props.textLines} style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize, color: this.props.color, lineHeight: this.props.lineHeight, marginBottom: this.props.marginBottom, marginTop: this.props.marginTop, textAlign: this.props.textAlign }}>{this.props.text}</Text>
        </View>
      )
    }

    if(this.props.headerHeading) {
      heading = (
        <Text allowFontScaling={false} ellipsizeMode='tail' numberOfLines="1" style={{ width: 100, fontFamily: this.props.fontFamily, fontSize: this.props.fontSize, color: this.props.color, lineHeight: this.props.lineHeight, marginBottom: this.props.marginBottom, marginTop: this.props.marginTop, textAlign: this.props.textAlign }}>{this.props.text}</Text>
      )
    }

    return heading;
  }
}
