import { Asset } from 'expo-asset';
import * as Font from 'expo-font';

export async function cacheAssets() {

    const json = [
    ];

    const images = [
    ];

    const fonts = [
        {
            'NunitoBlack': require('../../assets/fonts/Nunito-Black.ttf'),
        },
        {
            'NunitoBold': require('../../assets/fonts/Nunito-Bold.ttf'),
        },
        {
            'NunitoExtraBold': require('../../assets/fonts/Nunito-ExtraBold.ttf'),
        },
        {
            'NunitoLight': require('../../assets/fonts/Nunito-Light.ttf'),
        },
        {
            'NunitoRegular': require('../../assets/fonts/Nunito-Regular.ttf'),
        },
        {
            'NunitoSemiBold': require('../../assets/fonts/Nunito-SemiBold.ttf'),
        }
    ];

    const cacheJson = json.map((jsonItem) => {
        return Asset.fromModule(jsonItem).downloadAsync();
    });

    const cacheImages = images.map((image) => {
        return Asset.fromModule(image).downloadAsync();
    });

    const cacheFonts = fonts.map((font) => {
        return Font.loadAsync(font);
    });

    return Promise.all([...cacheFonts, ...cacheJson, ...cacheImages])

}