import React, { Component } from 'react';
import { View, Text, SafeAreaView, Image } from 'react-native';
import LottieView from 'lottie-react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';

export default class Loading extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.navigation.navigate('Landing')
    }, 2000);
  }

  render() {
    return (
      <View style={{ flex: 1, backgroundColor: '#FFF' }}>
        <SafeAreaView style={{ flex: 1, position: 'relative' }}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <LottieView source={require('../../assets/lottie/loading.json')} autoPlay loop />
          </View>
          <View style={{ width: "100%", position: 'absolute', bottom: 30, left: 0 }}>
            <View style={{ width: '100%', alignItems: 'center' }}>
                <Heading text="Produced By" color="#999" textAlign="center" fontSize={RF(1.8)} fontFamily="NunitoExtraBold" inline={true} />
            </View>
            <View style={{ width: '100%', alignItems: 'center' }}>
              <Image source={require('../../assets/img/image.jpg')} style={{ width: 80, height: 50 }} resizeMode="contain" />
            </View>
          </View>
        </SafeAreaView>
      </View>
    );
  }
  
}
