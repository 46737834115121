import React, { Component } from 'react';
import { View, TouchableOpacity, SafeAreaView, ScrollView, ActivityIndicator, TextInput, FlatList, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class NewForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            valid: false,
            form: [],
            name: '',
            id: ''
        };
    }

    componentDidMount() {
        let currentForm = this.props.route.params.details.form;
        let newForm = [];
        currentForm.map((item)=>{
            item.wrong = true;
            item.color = "#FF0000";
            newForm.push(item);
        })
        this.setState({
            form: newForm
        })
    }

    async update(index, value) {

        let currentState = await this.state.details;
        let newForm = this.state.form;

        let validate = this.checkValidation(newForm[index].type, value);
        if(validate) {

            newForm[index].wrong = false;
            newForm[index].color = "#FAFAFA";
            newForm[index].value = value;
            currentState.form = newForm;
            this.setState({
                details: currentState
            })

        }else{

            newForm[index].wrong = true;
            newForm[index].color = "#FF0000";
            newForm[index].value = value;
            currentState.form = newForm;
            this.setState({
                details: currentState
            })

        }

        this.checkFormFilledIn();

    }

    checkValidation(type, value) {

        if(type === "identification") {

            var idRegex = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/;
            if (idRegex.test(value)) {
                return true;
            } else {
                return false
            }

        }else if(type === 'email') {
            
            var emailRegex = /^([a-zA-Z0-9][-a-zA-Z0-9_\+\.]*[a-zA-Z0-9])@([a-zA-Z0-9][-a-zA-Z0-9\.]*[a-zA-Z0-9]\.(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|ARPA|ROOT|AERO|BIZ|CAT|COM|COOP|EDU|GOV|INFO|INT|JOBS|MIL|MOBI|MUSEUM|NAME|NET|ORG|PRO|TEL|TRAVEL|AC|AD|AE|AF|AG|AI|AL|AM|AN|AO|AQ|AR|AS|AT|AU|AW|AX|AZ|BA|BB|BD|BE|BF|BG|BH|BI|BJ|BM|BN|BO|BR|BS|BT|BV|BW|BY|BZ|CA|CC|CD|CF|CG|CH|CI|CK|CL|CM|CN|CO|CR|CU|CV|CX|CY|CZ|DE|DJ|DK|DM|DO|DZ|EC|EE|EG|ER|ES|ET|EU|FI|FJ|FK|FM|FO|FR|GA|GB|GD|GE|GF|GG|GH|GI|GL|GM|GN|GP|GQ|GR|GS|GT|GU|GW|GY|HK|HM|HN|HR|HT|HU|ID|IE|IL|IM|IN|IO|IQ|IR|IS|IT|JE|JM|JO|JP|KE|KG|KH|KI|KM|KN|KR|KW|KY|KZ|LA|LB|LC|LI|LK|LR|LS|LT|LU|LV|LY|MA|MC|MD|MG|MH|MK|ML|MM|MN|MO|MP|MQ|MR|MS|MT|MU|MV|MW|MX|MY|MZ|NA|NC|NE|NF|NG|NI|NL|NO|NP|NR|NU|NZ|OM|PA|PE|PF|PG|PH|PK|PL|PM|PN|PR|PS|PT|PW|PY|QA|RE|RO|RU|RW|SA|SB|SC|SD|SE|SG|SH|SI|SJ|SK|SL|SM|SN|SO|SR|ST|SU|SV|SY|SZ|TC|TD|TF|TG|TH|TJ|TK|TL|TM|TN|TO|TP|TR|TT|TV|TW|TZ|UA|UG|UK|UM|US|UY|UZ|VA|VC|VE|VG|VI|VN|VU|WF|WS|YE|YT|YU|ZA|ZM|ZW)|([0-9]{1,3}\.{3}[0-9]{1,3}))$/;
            if (emailRegex.test(value)) {
                return true;
            } else {
                return false
            }

        }else if(type === 'contact') {

            var contactRegex = /^0[0-9]\d{8}$/g;
            if (contactRegex.test(value)) {
                return true;
            } else {
                return false
            }

        }else{

            if(value) {

                return true;

            }else{

                return false;

            }

        }
    }

    async checkFormFilledIn() {

        let currentForm = await this.state.form;

        let valid = true;
        currentForm.map((form)=>{
            if(form.wrong) {
                valid = false;
            }
        })

        if(valid) {
            this.setState({
                valid: true
            })
        }else{
            this.setState({
                valid: false
            })
        }
        
    }

    async next() {
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/questions',
            data: {
                code: this.state.details.business.code,
                business: this.state.details.business.id
            }
        }).then((response) => {
            console.log(response.data);
            if(response.data.success === true) {
                let newObject = {
                    code: this.state.details.business.code,
                    business: this.state.details.business,
                    form: this.state.details.form,
                    questions: response.data.questions
                };
                this.state.navigation.navigate('Questions', { details: newObject });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        let layout = <View />;
        let questions = <View />;
        let button = <View />;
        
        if(this.state.form) {
            let json = this.state.form;
            if(json.length > 0) {
                questions = json.map((item, index)=>{
                    return (
                        <View key={index} style={{ width: '100%', alignItems: 'center', marginTop: 10 }}>
                            <View style={{ width: "100%", height: 50, position: 'relative'  }}>
                                <TextInput keyboardType="default" secureTextEntry={false} placeholder={item.placeholder} style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "#FAFAFA", padding: 10, fontFamily: 'NunitoExtraBold', borderRadius: 10, textAlign: 'left', fontSize: RF(2.5), color: "#000" }} onChangeText={(text)=>{this.update(index, text)}} />
                            </View>
                        </View>
                    )
                })
            }
        }

        if (this.state.loading) {
            layout = <ActivityIndicator />
        }else{
            layout = (
                <ScrollView style={{ flex: 1 }} keyboardDismissMode="on-drag">
                    {questions}
                </ScrollView>
            )
        }

        if(this.state.valid) {
            button = (
                <View style={{ width: "100%", paddingTop: 20, paddingHorizontal: 20, backgroundColor: "#F0F0F0" }}>
                    <TouchableOpacity onPress={() => this.next()}>
                        <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 2, borderColor: "#149f84", justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <Heading text="Next" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoExtraBold" inline={true} marginRight={10} />
                        </View>
                    </TouchableOpacity>
                </View>
            )
        }

        return (
            <View style={{ flex: 1, backgroundColor: "#FFF" }}>
                <SafeAreaView style={{ flex: 1, flexDirection: "column" }}>
                    <View style={{ width: "100%", marginTop: 30, marginBottom: 10 }}>
                        <View style={{ width: '100%' }}>
                            <Heading text="Please enter the details below" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoRegular" inline={true} />
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                        <FlatList
                            data={this.state.form}
                            extraData={this.state}
                            keyExtractor={(item, index) => index.toString() }
                            keyboardDismissMode="on-drag"
                            renderItem={(input) => {
                                return (
                                    <View key={input.index} style={{ width: '100%', alignItems: 'center', marginTop: 10 }}>
                                        <View style={{ width: "100%", height: 50, position: 'relative', borderColor: input.item.color, borderWidth: 1, borderRadius: 10,   }}>
                                            <TextInput keyboardType="default" secureTextEntry={false} placeholder={input.item.placeholder} style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "#FAFAFA", padding: 10, fontFamily: 'NunitoExtraBold', borderRadius: 10, textAlign: 'left', fontSize: RF(2.5), color: "#000000" }} onChangeText={(text)=>{this.update(input.index, text)}}  />
                                        </View>
                                    </View>
                                )
                            }}
                        />
                    </View>
                    {button}
                    <View style={{ width: "100%", paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, backgroundColor: "#F0F0F0" }}>
                        <Heading text="By completing this form process you agree to BlipperSCREEN's Terms of Use and acknowledge you have read the Privacy Policy." color="#999" textAlign="left" fontSize={RF(2.2)} fontFamily="NunitoRegular" inline={true} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
