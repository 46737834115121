import React, { Component } from 'react';
import { View, SafeAreaView, Dimensions, TextInput, TouchableOpacity, FlatList } from 'react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';
const width = Dimensions.get('window').width;

export default class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            searching: false,
            entries: []
        };
    }

    async search() {
        this.setState({
            searching: true
        })
    }

    async new() {
        this.state.navigation.navigate('Form', { details: this.state.details });
    }

    async update(index, value) {
        let currentState = await this.state.details;
        let newForm = this.state.form;
        newForm[index].value = value;
        currentState.form = newForm;
        this.setState({
            details: currentState
        })
        console.log(this.state);
    }

    render() {

        let layout= (
            <View style={{ width: "100%", flexDirection: "row", paddingLeft: 10, paddingRight: 10 }}>
                <View style={{ width: "50%", paddingRight: 10 }}>
                    <TouchableOpacity onPress={()=> this.search() }>
                        <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                            <Heading text="Search" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={{ width: "50%", paddingLeft: 10 }}>
                    <TouchableOpacity onPress={()=> this.new() }>
                        <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                            <Heading text="New" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        )

        if(this.state.searching) {

            layout = (
                <View style={{ flex: 1 }}>
                    <View style={{ width: "100%", marginBottom: 10 }}>
                        <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center', marginTop: 10 }}>
                            <View style={{ width: "100%", height: 50, position: 'relative'  }}>
                                <TextInput keyboardType="default" secureTextEntry={false} placeholder="Search a person" style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "#FFF", padding: 10, fontFamily: 'NunitoExtraBold', borderRadius: 10, textAlign: 'center' }} returnKeyType="done" onSubmitEditing={this.next} onChangeText={ (text) => { this.setState({ code: text }) } } />
                            </View>
                        </View>
                    </View>
                    <FlatList
                        numColumns={2}
                        data={this.state.entries}
                        extraData={this.state}
                        keyExtractor={(iten, index) => index.toString}
                        renderItem={(item, index)=>{
                            return (
                                <View style={{ width: width / 2, paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
                                    <TouchableOpacity>
                                        <View style={{ width: "100%", height: 50, borderRadius: 5, borderWidth: 1, borderColor: "#FFF" }}>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            )
                        }}
                    />
                </View>
            )

        }

        return (
            <View style={{ flex: 1 }}>
                <SafeAreaView style={{ flex: 1, justifyContent: 'center' }}>
                    <View style={{ flex: 1, backgroundColor: '#9B66FE', margin: 20, paddingLeft: 20, paddingRight: 20, position: "relative", borderRadius: 20, flexDirection: 'column' }}>
                        {layout}
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
