import React, { Component } from 'react';
import { View, SafeAreaView, TouchableOpacity, KeyboardAvoidingView, Platform } from 'react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import { TextInput, ScrollView } from 'react-native-gesture-handler';

export default class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            code: '',
            scanners: []
        };
    }

    async start() {
        this.state.navigation.navigate('Landing');
    }

    render() {

        return (
            <View style={{ flex: 1 }}>
                <SafeAreaView style={{ flex: 1, justifyContent: 'center' }}>
                    <View style={{ flex: 1, backgroundColor: '#9B66FE', margin: 20, paddingLeft: 20, paddingRight: 20, position: "relative", borderRadius: 20, flexDirection: 'column' }}>
                        <View style={{ flexGrow: 1, paddingLeft: 10, paddingRight: 10, justifyContent: 'center' }}>
                            <Heading text="Thank you for submitting your COVID19 report for today?" color="#FFFF" textAlign="center" fontSize={RF(4.0)} fontFamily="NunitoExtraBold" inline={true} />
                        </View>
                        <View style={{ width: '100%', paddingLeft: 30, paddingRight: 30, alignItems: 'center', marginTop: 10, flexDirection: 'row', paddingBottom: 30, left: 0, justifyContent: 'center' }}>
                            <View style={{ width: "50%", paddingLeft: 10 }}>
                                <TouchableOpacity onPress={()=> this.start() }>
                                    <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                                        <Heading text="New" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
