import React, { Component } from 'react';
import { View, SafeAreaView, TextInput, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import { AntDesign } from '@expo/vector-icons';
const width = Dimensions.get('window').width;

export default class Questions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            details: this.props.route.params.details,
            questions: [],
            next: false
        }
    }

    componentDidMount() {
        this.setState({
            questions: this.state.details.questions
        },()=>{
            this.checkAnswers()
        })
    }

    async selectAllNo() {
        let newQuestions = [];
        let questions = this.state.questions;
        questions.map((item)=>{
            item.answer = false;
            newQuestions.push(item);
        })
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async selectAllYes() {
        let newQuestions = [];
        let questions = this.state.questions;
        questions.map((item)=>{
            item.answer = true;
            newQuestions.push(item);
        })
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async yes(index) {
        let newQuestions = this.state.questions;
        newQuestions[index].answer = true;
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async no(index) {
        let newQuestions = this.state.questions;
        newQuestions[index].answer = false;
        this.setState({
            questions: newQuestions
        },()=>{
            this.checkAnswers()
        })
    }

    async checkAnswers() {
        let completed = true;
        let allQuestions = this.state.questions;
        allQuestions.map((question)=>{
            if(question.answer === false || question.answer === true){

            }else{
                completed = false;
            }
        })
        if(completed) {
            this.setState({
                next: true
            })
        }
    }

    async next() {
        this.state.navigation.navigate('Temprature', { details: this.state.details });
    }

    render() {

        let questions = <View />;

        let json = this.state.questions;
        questions = json.map((item, index)=>{
            let buttonNo = (
                <View style={{ width: "50%", paddingRight: 10 }}>
                    <TouchableOpacity onPress={()=>this.no(index)}>
                        <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                            <Heading text="No" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                        </View>
                    </TouchableOpacity>
                </View>
            );
            let buttonYes = (
                <View style={{ width: "50%", paddingLeft: 10 }}>
                    <TouchableOpacity onPress={()=> this.yes(index) }>
                        <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                            <Heading text="Yes" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                        </View>
                    </TouchableOpacity>
                </View>
            );

            if(item.answer === true) {
                buttonYes = (
                    <View style={{ width: "50%", paddingLeft: 10 }}>
                        <TouchableOpacity onPress={()=> this.yes(index) }>
                            <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFF' }}>
                                <Heading text="Yes" color="#9B66FE" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                            </View>
                        </TouchableOpacity>
                    </View>
                );
            }
            
            if(item.answer === false) {
                buttonNo = (
                    <View style={{ width: "50%", paddingRight: 10 }}>
                        <TouchableOpacity onPress={()=> this.no(index) }>
                            <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFF' }}>
                                <Heading text="No" color="#9B66FE" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                            </View>
                        </TouchableOpacity>
                    </View>
                );
            }

            return (
                <View key={index} style={{ width: "100%", backgroundColor: '#9B66FE', marginBottom: 10, justifyContent: 'center', borderRadius: 20, padding: 20 }}>
                    <View style={{ width: '100%', paddingLeft: 20, paddingRight: 20, alignItems: 'center' }}>
                        <Heading text={item.name} color="#FFFF" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoExtraBold" />
                    </View>
                    <View style={{ width: '100%', paddingLeft: 30, paddingRight: 30, alignItems: 'center', marginTop: 10, flexDirection: 'row' }}>
                        {buttonNo}
                        {buttonYes}
                    </View>
                </View>
            )
        })

        let nextButton = <View />;

        if(this.state.next) {
            nextButton = (
                <View style={{ width: "100%", flexDirection: 'row' }}>
                    <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <TouchableOpacity onPress={() => this.next()}>
                            <View style={{ padding: 15, flexDirection: 'row' }}>
                                <Heading fontFamily="NunitoBlack" fontSize={RF(3.0)} text="Next" inline={true} color="#9B66FE" />
                                <View style={{ justifyContent: "center", alignItems: "flex-end", marginLeft: 5 }}>
                                    <AntDesign name="arrowright" size={25} color="#9B66FE" />
                                </View>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        }

        return (
            <View style={{ flex: 1 }}>
                <SafeAreaView style={{ flex: 1, justifyContent: 'center' }}>
                    <View style={{ width: "100%", flexDirection: 'row' }}>
                        <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <TouchableOpacity onPress={() => this.selectAllNo()}>
                                <View style={{ padding: 15, flexDirection: 'row' }}>
                                    <Heading fontFamily="NunitoBlack" fontSize={RF(3.0)} text="All No" inline={true} color="#9B66FE" />
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            <TouchableOpacity onPress={() => this.selectAllYes()}>
                                <View style={{ padding: 15, flexDirection: 'row' }}>
                                    <Heading fontFamily="NunitoBlack" fontSize={RF(3.0)} text="All Yes" inline={true} color="#9B66FE" />
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <ScrollView style={{ flex: 1, paddingLeft: 10, paddingRight: 10, flexWrap: 1, }}>
                        {questions}
                    </ScrollView>
                    {nextButton}
                </SafeAreaView>
            </View>
        );
    }

}
