import React, { Component } from 'react';
import { AppLoading } from 'expo';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { cacheAssets } from './src/tools/Cache';
import Temprature from './src/screens/Temprature';
import Complete from './src/screens/Complete';
import Loading from './src/screens/Loading';
import Landing from './src/screens/Landing';
import Business from './src/screens/Business';
import Questions from './src/screens/Questions';
import Form from './src/screens/Form';
import Search from './src/screens/Search';
import NewLanding from './src/screens/NewLanding';
import NewBusiness from './src/screens/NewBusiness';
import NewForm from './src/screens/NewForm';
import NewQuestions from './src/screens/NewQuestions';
import NewTemprature from './src/screens/NewTemprature';
import NewComplete from './src/screens/NewComplete';
import NewCompleteTemprature from './src/screens/NewCompleteTemprature';
import NewCompleteSick from './src/screens/NewCompleteSick';
const Stack = createStackNavigator();

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    };
  }

  render() {

    if (!this.state.isReady) {
      return (
        <AppLoading
          startAsync={cacheAssets}
          onFinish={() => this.setState({ isReady: true })}
          onError={console.warn}
        />
      );
    }

    return (
      <NavigationContainer>
        <Stack.Navigator initialRouteName="Landing" screenOptions={{
          headerShown: false,
          gestureEnabled: false
        }}>
          <Stack.Screen name="Loading" component={Loading} />
          <Stack.Screen name="Landing" component={NewLanding} />
          <Stack.Screen name="Business" component={NewBusiness} />
          <Stack.Screen name="Search" component={Search} />
          <Stack.Screen name="Form" component={NewForm} />
          <Stack.Screen name="Questions" component={NewQuestions} />
          <Stack.Screen name="Temprature" component={NewTemprature} />
          <Stack.Screen name="Complete" component={NewComplete} />
          <Stack.Screen name="CompleteTemp" component={NewCompleteTemprature} />
          <Stack.Screen name="CompleteSick" component={NewCompleteSick} />
        </Stack.Navigator>
      </NavigationContainer>
    );

  }

}
