import React, { Component } from 'react';
import { View, SafeAreaView, TouchableOpacity, KeyboardAvoidingView, Platform, TextInput, ScrollView } from 'react-native';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation,
            code: '',
            scanners: []
        };
    }

    async next() {
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/business',
            data: {
                code: this.state.code
            }
        }).then((response) => {
            if(response.data.success === true) {
                let newObject = {
                    code: this.state.code,
                    business: response.data.business
                };
                this.state.navigation.navigate('Business', { details: newObject });
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        return (
            <View style={{ flex: 1 }}>
                <SafeAreaView style={{ flex: 1, justifyContent: 'center' }}>
                    <View style={{ flex: 1, backgroundColor: '#9B66FE', margin: 20, paddingLeft: 20, paddingRight: 20, position: "relative", borderRadius: 20 }}>
                        <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }} behavior={Platform.OS == "ios" ? "padding" : "height"}>
                            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center' }}>
                                <Heading text="Please enter your code" color="#FFFF" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoExtraBold" inline={true} />
                            </View>
                            <View style={{ width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center', marginTop: 10 }}>
                                <View style={{ width: "50%", height: 50, position: 'relative'  }}>
                                    <TextInput keyboardType="default" secureTextEntry={true} placeholder="Your Code" style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "#FFF", padding: 10, fontFamily: 'NunitoExtraBold', borderRadius: 10, textAlign: 'center' }} returnKeyType="done" onSubmitEditing={this.next} onChangeText={ (text) => { this.setState({ code: text }) } } />
                                </View>
                            </View>
                        </KeyboardAvoidingView>
                        <View style={{ width: '100%', marginTop: 10, flexDirection: 'row', paddingBottom: 20, justifyContent: 'center' }}>
                            <View style={{ width: "50%" }}>
                                <TouchableOpacity onPress={()=> this.next() }>
                                    <View style={{ width: '100%', height: 50, borderRadius: 10, borderWidth: 1, borderColor: "#FFF", justifyContent: 'center', alignItems: 'center' }}>
                                        <Heading text="Next" color="#FFFF" textAlign="center" fontSize={RF(3.5)} fontFamily="NunitoRegular" inline={true} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </View>
        );
    }

}
