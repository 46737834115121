import React, { Component } from 'react';
import { View, Keyboard, SafeAreaView, Image, ActivityIndicator } from 'react-native';
import SmoothPinCodeInput from 'react-native-smooth-pincode-input';
import Heading from '../components/Heading';
import RF from '../tools/RF';
import axios from 'axios';

export default class NewLanding extends Component {

    constructor(props) {
        super(props);
        this.pinInput = React.createRef();
        this.state = {
            navigation: this.props.navigation,
            loading: false,
            code: ''
        };
    }

    fulfilled() {
        this.setState({
            loading: true
        })
        Keyboard.dismiss();
        setTimeout(() => {
            this.check();
        }, 2000);
    }

    async check() {
        await axios({
            method: 'POST',
            url: 'https://blipperscreen.co.za/api/business',
            data: {
                code: this.state.code
            }
        }).then((response) => {
            this.setState({
                code: '',
                loading: false
            })
            if(response.data.success === true) {
                let newObject = {
                    code: this.state.code,
                    business: response.data.business
                };
                this.state.navigation.navigate('Business', { details: newObject });
            }else{
                this.pinInput.current.shake();
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        let pin = <SmoothPinCodeInput
            placeholder={
                <View style={{ width: 10, height: 10, borderRadius: 25, opacity: 0.3, backgroundColor: '#999', }}></View>
            }
            mask={
                <View style={{ width: 10, height: 10, borderRadius: 25, backgroundColor: '#a70f14', }}></View>
            }
            ref={this.pinInput}
            autoFocus={false}
            codeLength={4}
            maskDelay={1000}
            password={true}
            cellStyle={null}
            cellStyleFocused={null}
            value={this.state.code}
            onTextChange={code => this.setState({ code: code })}
            onFulfill={() => { this.fulfilled() }}
        />

        let layout = (
            <View style={{ width: "100%" }}>
                <View style={{ width: '100%' }}>
                    <Heading text="Tap & Enter your PIN" color="#999" textAlign="center" fontSize={RF(3.0)} fontFamily="NunitoRegular" inline={true} />
                </View>
                <View style={{ width: '100%', alignItems: 'center' }}>
                    {pin}
                </View>
            </View>
        )

        if(this.state.loading) {
            layout = <ActivityIndicator />
        }

        return (
            <View style={{ flex: 1, backgroundColor: "#FFF" }}>
                <SafeAreaView style={{ flex: 1, flexDirection: "column" }}>
                    <View style={{ width: "100%", marginTop: 20 }}>
                        <View style={{ width: "100%" }}>
                            <Image source={require('../../assets/img/icon.jpg')} resizeMode="contain" style={{ width: "100%", height: 120 }} />
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, justifyContent: 'center', alignItems: "center", flexDirection: "column", paddingLeft: 20, paddingRight: 20 }}>
                        {layout}
                    </View>
                    <View style={{ width: "100%", marginBottom: 15, marginTop: 15, paddingLeft: 20, paddingRight: 20 }}>
                        <Heading text="By completing this form process you agree to BlipperSCREEN's Terms of Use and acknowledge you have read the Privacy Policy." color="#999" textAlign="left" fontSize={RF(2.2)} fontFamily="NunitoRegular" inline={true} />
                    </View>
                </SafeAreaView>
            </View>
        );
    }
}
